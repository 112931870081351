<script>
import moment from 'moment';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import annotationsInit from 'highcharts/modules/annotations';

exportingInit(Highcharts);
exportDataInit(Highcharts);
annotationsInit(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

export default {
  name: 'EnergyGraph',
  components: {
    Chart
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    accountId: {
      type: String,
      required: false,
      default: ''
    },
    filters: {
      type: Object,
      required: false,
      default: () => ({
        startDate: moment().subtract(1, 'year').subtract(1, 'day').format('DD MMM YYYY'),
        endDate: moment().subtract(1, 'day').format('DD MMM YYYY'),
        comparePeriod: 'period'
      })
    }
  },
  data() {
    return {
      apiFormat: 'DD/MM/YYYY',
      longFormat: 'DD MMM YYYY',
      chart: {
        data: []
      },
      predefinedRanges: [
        { label: 'Past month', params: [1, 'month'] },
        { label: 'Past 3 months', params: [3, 'months'] },
        { label: 'Past 12 months', params: [12, 'months'] },
        { label: 'Quarter to date', params: [0, 'quarter', true] },
        { label: 'Year to date', params: [0, 'year', true] },
        { label: '2020', params: [1, 'year', true, true] },
        { label: '2019', params: [2, 'year', true, true] },
        { label: '2018', params: [3, 'year', true, true] },
        { label: 'All time', params: [] },
        { label: 'Custom', params: [] }
      ],
      comparePeriods: [
        { label: 'Previous period', value: 'period' },
        { label: 'Previous month', value: 'month' },
        { label: 'Previous quarter', value: 'quarter' },
        { label: 'Previous year', value: 'year' },
        { label: 'No comparison', value: null }
      ],
      defaultChartOptions: {
        title: {
          text: 'Graph',
          useHTML: true
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Unit'
          },
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.name}: {point.y:,.0f}'
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          },
          line: {
            marker: {
              symbol: 'circle'
            }
          }
        },
        colors: ['#3c5c61', '#4d9575', '#3a7973', '#79af6c', '#b6c35f', '#ffd15e'],
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                plotOptions: {
                  series: {
                    marker: {
                      radius: 2.5
                    }
                  }
                }
              }
            }
          ]
        },
        credits: {
          enabled: false
        }
      }
    };
  }
};
</script>
<style lang="scss">
.EnergyGraph {
  height: 500px;
}
</style>
