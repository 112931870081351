<template>
  <div class="block block-rounded block-fx-pop js-appear-enabled animated fadeIn" data-toggle="appear">
    <div class="block-content block-content-full">
      <Chart v-if="!isLoading" :options="chartOptions" />
      <div v-if="isLoading" class="d-flex justify-content-center">
        <div class="text-center">
          <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
            <span class="sr-only">Loading graph...</span>
          </div>
          <div class="font-w600">Loading graph...</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

import EnergyGraph from './EnergyGraph';

export default {
  name: 'AssetEnergyGraph',
  extends: EnergyGraph,
  props: {
    chartType: {
      type: String,
      required: false,
      default: 'account-energy-usage'
    },
    filters: {
      type: Object,
      required: false,
      default: () => ({
        type: 'account-energy',
        field: 'totalUnits'
      })
    }
  },
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      accounts: 'asset/accounts',
      loadingAction: 'asset/loadingAction'
    }),
    chartOptions() {
      return {
        ...this.defaultChartOptions,
        ...this.options,
        xAxis: {
          ...this.defaultChartOptions.xAxis,
          ...this.options.xAxis
        },
        yAxis: {
          ...this.defaultChartOptions.yAxis,
          ...this.options.yAxis
        },
        tooltip: {
          ...this.defaultChartOptions.tooltip,
          ...this.options.tooltip
        },
        series: this.chart.data
      };
    }
  },
  async mounted() {
    await this.getAccounts({ id: this.$route.params.id });
    this.account = this.accounts.find(a => a._id === this.accountId);
    this.getAccountEnergyUsage();
  },
  methods: {
    ...mapActions({
      getAssetAnalytics: 'asset/getAssetAnalytics',
      getAccounts: 'asset/accounts'
    }),
    ...mapMutations({
      updateEnergy: 'asset/UPDATE_ACCOUNT_ENERGY'
    }),
    async getAccountEnergyUsage() {
      this.isLoading = true;

      let typeFormatted = '';
      this.filters.type.split('').forEach((c, index) => {
        if (c !== '-') {
          if (this.filters.type[index - 1] === '-') {
            typeFormatted += c.toUpperCase();
          } else {
            typeFormatted += c;
          }
        }
      });

      this.chart.data = await this.getAssetAnalytics({
        id: this.$route.params.id,
        options: {
          ...this.filters,
          type: this.chartType,
          startDate: moment(this.filters.startDate, this.longFormat).format(this.apiFormat),
          endDate: moment(this.filters.endDate, this.longFormat).format(this.apiFormat)
        },
        type: typeFormatted
      });
      this.$emit('data', this.chart.data);
      this.isLoading = false;
    }
  }
};
</script>
