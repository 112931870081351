var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row no-gutters flex-md-10-auto",
    attrs: {
      "data-cy": "project-view-page"
    }
  }, [_c('div', {
    staticClass: "col-md-4 col-lg-5 col-xl-4 order-md-1"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('h2', {
    staticClass: "h2 font-w400 mb-3"
  }, [_vm._v(_vm._s(_vm.project.name))])]), _c('div', {
    staticClass: "col-md-4 text-right",
    attrs: {
      "data-cy": "project-edit"
    }
  }, [_vm.$permissions.write('asset', _vm.asset) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'asset-projects-edit',
        params: {
          projectId: _vm.project._id
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pencil mr-1"
  }), _vm._v(" Edit ")]) : _vm._e()], 1)]), _c('h2', {
    staticClass: "h4 font-w400 mb-3"
  }, [_vm._v("Completion")]), _c('div', {
    staticClass: "progress push"
  }, [_c('div', {
    staticClass: "progress-bar",
    style: {
      width: "".concat(_vm.progress, "%")
    }
  }, [_c('span', {
    staticClass: "font-size-sm font-w600"
  }, [_vm._v(_vm._s(_vm.progress) + "%")])])]), _c('p', {
    staticClass: "text-muted pt-3 my-3"
  }, [_c('span', {
    staticClass: "font-w600 font-size-h5"
  }, [_vm._v("Summary")]), _c('br'), _c('span', {
    staticClass: "project-description"
  }, [_vm._v(_vm._s(_vm.project.description))])]), _c('p', {
    staticClass: "text-muted border-top pt-3 my-3"
  }, [_c('span', {
    staticClass: "font-w600 font-size-h5"
  }, [_vm._v("Status")]), _c('br'), _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.project.status))])]), _c('p', {
    staticClass: "text-muted border-top pt-3 my-3"
  }, [_c('span', {
    staticClass: "font-w600 font-size-h5"
  }, [_vm._v("Start")]), _c('br'), _vm._v(" " + _vm._s(_vm._f("date")(_vm.project.startDate, 'Do MMM YYYY')) + " ")]), _c('p', {
    staticClass: "text-muted border-top pt-3 my-3"
  }, [_c('span', {
    staticClass: "font-w600 font-size-h5"
  }, [_vm._v("End")]), _c('br'), _vm._v(" " + _vm._s(_vm._f("date")(_vm.project.endDate, 'Do MMM YYYY')) + " ")]), _c('div', {
    staticClass: "text-muted border-top pt-3 my-3"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h5 mb-2"
  }, [_vm._v("Accounts")]), _vm._l(_vm.accountsAssigned, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "MultiSelect-item mb-1 p-2 font-w600"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c('div', {
    staticClass: "text-muted border-top pt-3 my-3"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h5 mb-2"
  }, [_vm._v("Document Attachments")]), _vm._l(_vm.projectDocuments, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "MultiSelect-item mb-1 p-2 font-w600"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickDownload(item);
        }
      }
    }, [_c('FileIcon', {
      staticClass: "fa-fw mr-1",
      attrs: {
        "extension": item.extension || item.type
      }
    }), _vm._v(" " + _vm._s(item.name) + "." + _vm._s(item.extension))], 1)]);
  }), _vm._l(_vm.project.documents, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "MultiSelect-item mb-1 p-2 font-w600"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickDownload(item, true);
        }
      }
    }, [_c('FileIcon', {
      staticClass: "fa-fw mr-1",
      attrs: {
        "extension": item.extension || item.type
      }
    }), _vm._v(" " + _vm._s(item.name))], 1)]);
  })], 2)])]), _c('div', {
    staticClass: "col-md-8 col-lg-7 col-xl-8 order-md-0 bg-body-dark"
  }, [_c('div', {
    staticClass: "content content-full"
  }, [_c('h3', [_vm._v("Tasks")]), _vm.$permissions.write('asset', _vm.asset) ? _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmitTask($event);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('FormItem', {
    attrs: {
      "id": "task-input",
      "type": "text",
      "name": "task-input",
      "placeholder": "Add a task and press enter..",
      "disabled": _vm.loadingAction.createProjectTask
    },
    model: {
      value: _vm.taskInput,
      callback: function callback($$v) {
        _vm.taskInput = $$v;
      },
      expression: "taskInput"
    }
  }), _vm.loadingAction.createProjectTask ? _c('span', {
    staticClass: "ml-2"
  }, [_c('i', {
    staticClass: "fa fa-spinner fa-spin fa-fw"
  })]) : _vm._e()], 1)]) : _vm._e(), _c('h2', {
    staticClass: "content-heading pb-0 mb-3 border-0"
  }, [_vm._v(" Pending "), _c('span', {
    staticClass: "js-task-badge-completed badge badge-pill badge-light animated fadeIn"
  }, [_vm._v(_vm._s(_vm.pendingTasks.length))])]), _vm._l(_vm.pendingTasks, function (task) {
    return _c('div', {
      key: task._id,
      staticClass: "block block-rounded mb-2 py-2 px-3 d-flex justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-primary custom-control-lg"
    }, [_vm.$permissions.write('asset', _vm.asset) ? _c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": task._id,
        "type": "checkbox",
        "name": task._id
      },
      on: {
        "input": function input($event) {
          $event.preventDefault();
          return _vm.onCheckTask(task, 'completed');
        }
      }
    }) : _vm._e(), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": task._id
      }
    }), _c('strong', [_vm._v(_vm._s(task.name))])]), _c('div', [_vm.$permissions.write('asset', _vm.asset) ? _c('button', {
      staticClass: "btn btn-sm btn-link text-danger",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onDeleteTask(task._id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times fa-fw"
    })]) : _vm._e()])]);
  }), _c('h2', {
    staticClass: "content-heading pb-0 mb-3 border-0"
  }, [_vm._v(" Completed "), _c('span', {
    staticClass: "js-task-badge-completed badge badge-pill badge-light animated fadeIn"
  }, [_vm._v(_vm._s(_vm.completedTasks.length))])]), _vm._l(_vm.completedTasks, function (task) {
    return _c('div', {
      key: task._id,
      staticClass: "block block-rounded mb-2 py-2 px-3 d-flex justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-primary custom-control-lg"
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": task._id,
        "type": "checkbox",
        "name": task._id,
        "checked": ""
      },
      on: {
        "input": function input($event) {
          $event.preventDefault();
          return _vm.onCheckTask(task, 'pending');
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": task._id
      }
    }), _c('strong', [_c('del', [_vm._v(_vm._s(task.name))])])]), _c('div', [_vm.$permissions.write('asset', _vm.asset) ? _c('button', {
      staticClass: "btn btn-sm btn-link text-danger",
      attrs: {
        "type": "button",
        "disabled": _vm.loadingAction.deleteProjectTask === task._id
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onDeleteTask(task._id);
        }
      }
    }, [_vm.loadingAction.deleteProjectTask !== task._id ? _c('i', {
      staticClass: "fa fa-times fa-fw"
    }) : _c('i', {
      staticClass: "fa fa-spinner fa-spin fa-fw"
    })]) : _vm._e()])]);
  })], 2), _c('div', {
    staticClass: "content content-full"
  }, [_c('h3', [_vm._v("Analysis")]), !_vm.selectedAccount && !_vm.loading ? _c('div', {
    staticClass: "alert alert-primary"
  }, [_vm._v(" Link this project to an account to see energy data before and after the project end date. ")]) : _vm.selectedAccount && !_vm.loading ? _c('div', [_c('p', [_vm._v("See the energy usage before/after this project ended.")]), _vm.project.status === 'completed' && _vm.completedProjectCheck && _vm.project.accountIds.length !== 0 ? _c('div', [_c('FormGroup', {
    attrs: {
      "id": "accounts",
      "label": "Select Account to Analyse",
      "placeholder": "Add an account...",
      "type": "select",
      "options": _vm.accountsAssigned.map(function (a) {
        return {
          label: a.name,
          value: a.id
        };
      })
    },
    on: {
      "input": _vm.handleSelect
    },
    model: {
      value: _vm.selectedAccount,
      callback: function callback($$v) {
        _vm.selectedAccount = $$v;
      },
      expression: "selectedAccount"
    }
  }), !_vm.loadingAction.getAssetAnalytics.accountEnergy ? _c('div', [_vm._m(0), _c('p', {
    staticClass: "ml-5"
  }, [_vm._v(_vm._s(_vm.handleTotalConsumptionBefore) + " kWh")]), _vm._m(1), _c('p', {
    staticClass: "ml-5"
  }, [_vm._v(_vm._s(_vm.handleTotalConsumptionAfter) + " kWh")])]) : _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._m(2)]), _c('div', {
    key: _vm.chartKey,
    attrs: {
      "data-cy": "project-graphs"
    }
  }, [_c('AssetEnergyGraph', {
    attrs: {
      "options": _vm.consumptionChartOptions,
      "filters": _vm.consumptionChartFilters
    },
    on: {
      "data": _vm.handleConsumptionData
    }
  }), !_vm.$auth.settings.hideCostData ? _c('AssetEnergyGraph', {
    attrs: {
      "options": _vm.costChartOptions,
      "filters": _vm.costChartFilters
    }
  }) : _vm._e()], 1)], 1) : _vm.project.accountIds.length !== 0 ? _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "data-cy": "data-error"
    }
  }, [_vm._v(" Graphs and data will show once this project has completed. ")]) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "data-cy": "account-error"
    }
  }, [_vm._v("No account assigned to project.")])]) : _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._m(3)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_c('div', {
    staticClass: "mb-3"
  }, [_vm._v("Consumption 12 months prior to completion:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_c('div', {
    staticClass: "mb-3"
  }, [_vm._v("Consumption 12 months after completion:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading data...")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading data...")])])]);
}]

export { render, staticRenderFns }