var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block block-rounded block-fx-pop js-appear-enabled animated fadeIn",
    attrs: {
      "data-toggle": "appear"
    }
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [!_vm.isLoading ? _c('Chart', {
    attrs: {
      "options": _vm.chartOptions
    }
  }) : _vm._e(), _vm.isLoading ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._m(0)]) : _vm._e()], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading graph...")])]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Loading graph...")])]);
}]

export { render, staticRenderFns }